const getters = {
  isAdminUser: (state) => {
    const { groups = [] } = state.user;
    const foundAdminGroup = groups.find((item) => ["super_admin", "admin"].includes(item.code));

    return !!foundAdminGroup;
  },
};

export default getters;
